import * as React from 'react';
import styled from 'styled-components';
import ProductCard from 'styleguide/components/Cards/ProductCard/ProductCard';
import OutlinedButtonLink from 'styleguide/components/Buttons/OutlinedButton/OutlinedButtonLink';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import { media } from 'styleguide/helpers/media';
import { ProductCardSizes } from 'styleguide/components/Cards/ProductCard/ProductCard';
import { ProductHighlightConfiguration, ProductHighlightLayouts } from './interfaces/ProductHighlight';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import { productCardGridStyles } from '../General';
import WidgetText from './components/WidgetText';
import { WidgetCommonProps } from './interfaces/interfaces';
import { Theme } from 'styleguide/themes/theme';
import { MapStateToProps, connect } from 'react-redux';
import { State } from 'common/reducers';
import { getThemeForResource } from '../App/services';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';
import { localeSelector } from 'common/selectors/localeSelector';
import OddlySubTitle, { OddlySubTitleType } from '../Oddlygood/OddlySubTitle';
import OddlyWrapper, { OddlyWrapperBackground } from '../Oddlygood/OddlyWrapper';
import OddlyWrapperInner from '../Oddlygood/OddlyWrapperInner';

const StyledProductHighlightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px 0 50px;
	width: 100%;

	${media.tablet`
		padding: 50px 0 60px;
	`};

	.medium-header {
		margin-top: 0;
		text-align: center;
	}

	.product-highlights-text {
		margin: 0 0 20px;
	}
`;

const OddlygoodStyledProductHighlightWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px 0 20px;
	width: 100%;

	${media.tablet`
		padding: 20px 0 20px;
	`};

	.medium-header {
		margin-top: 0;
		text-align: center;
	}

	.product-highlights-text {
		margin: 0 0 20px;
	}
`;

const WidgetHeader = styled(MediumHeader)`
	max-width: 720px;
`;

const StyledProductHighlightItemContainer = styled.div<{
	layoutName?: ProductHighlightLayouts;
	size?: ProductCardSizes;
	useUpperMargin?: boolean;
}>`
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	margin: 0 -3px 30px;

	${({ useUpperMargin }) => useUpperMargin && 'margin-top: 15px'};

	${media.desktop1440`
		min-width: 1040px;
	`};

	${({ layoutName, size }) => productCardGridStyles(layoutName, size)};
`;

type OwnProps = WidgetCommonProps & ProductHighlightConfiguration;

const ProductHighlight: React.FC<OwnProps & ProductHighlightStateProps & InjectedRoutableProps> = ({
	widgetId,
	title,
	text,
	resourceItems,
	additionalLink,
	additionalLinkText,
	layoutName,
	onLinkClick,
	headerTagName,
	useLazyImages,
	siteTheme,
	language,
}) => {
	const displayedProducts = resourceItems || [];

	let size: ProductCardSizes | undefined;
	if (layoutName === 'fourColumn') {
		size = 'medium';
	}
	if (layoutName === 'threeColumn' || siteTheme === 'oddlygood' || layoutName === 'oddlygood3c') {
		size = 'large';
	}

	// =============================
	// ODDLYGOOD LAYOUT
	// =============================
	if (layoutName === 'oddlygood' || layoutName === 'oddlygood3c') {
		const background = layoutName === 'oddlygood3c' ? OddlyWrapperBackground.White : OddlyWrapperBackground.Tertiary;

		return (
			<ContentThemeContext.Consumer>
				{contentTheme => (
					<OddlyWrapper background={background}>
						<OddlyWrapperInner background={background} desktopPadding={1}>
							<OddlygoodStyledProductHighlightWrapper id={widgetId}>
								{title && <OddlySubTitle title={title} type={OddlySubTitleType.Primary} />}

								{text && <WidgetText className="product-highlights-text">{text}</WidgetText>}

								<StyledProductHighlightItemContainer layoutName={layoutName} size={size} useUpperMargin={true}>
									{displayedProducts.map((p, i) => {
										return (
											<ProductCard
												key={i}
												imageUrl={p.imageUrl}
												imagePresetSuffix={'product-thumbnail'}
												isNovelty={p.isNovelty}
												size={size}
												productName={p.title}
												useImagePresets={true}
												url={p.url}
												className="product-card"
												onClick={onLinkClick}
												useLazyImage={useLazyImages}
												useLargeTextSize={siteTheme === 'oddlygood'}
												language={language}
											/>
										);
									})}
								</StyledProductHighlightItemContainer>
								{additionalLink && additionalLinkText && (
									<OutlinedButtonLink href={additionalLink} buttonStyle="secondary">
										{additionalLinkText}
									</OutlinedButtonLink>
								)}
							</OddlygoodStyledProductHighlightWrapper>
						</OddlyWrapperInner>
					</OddlyWrapper>
				)}
			</ContentThemeContext.Consumer>
		);
	}

	// =============================
	// OTHER LAYOUTS
	// =============================
	return (
		<ContentThemeContext.Consumer>
			{contentTheme => (
				<StyledProductHighlightWrapper id={widgetId}>
					<WidgetHeader className="medium-header" tagName={headerTagName} color={contentTheme.color}>
						{title}
					</WidgetHeader>

					{text && <WidgetText className="product-highlights-text">{text}</WidgetText>}

					<StyledProductHighlightItemContainer layoutName={layoutName} size={size}>
						{displayedProducts.map((p, i) => {
							return (
								<ProductCard
									key={i}
									imageUrl={p.imageUrl}
									imagePresetSuffix={'product-thumbnail'}
									isNovelty={p.isNovelty}
									size={size}
									productName={p.title}
									useImagePresets={true}
									url={p.url}
									className="product-card"
									onClick={onLinkClick}
									useLazyImage={useLazyImages}
									useLargeTextSize={siteTheme === 'oddlygood'}
									language={language}
								/>
							);
						})}
					</StyledProductHighlightItemContainer>
					{additionalLink && additionalLinkText && (
						<OutlinedButtonLink href={additionalLink} color={contentTheme.color}>
							{additionalLinkText}
						</OutlinedButtonLink>
					)}
				</StyledProductHighlightWrapper>
			)}
		</ContentThemeContext.Consumer>
	);
};

interface ProductHighlightStateProps {
	siteTheme?: Theme;
	language: string;
}

const mapStateToProps: MapStateToProps<ProductHighlightStateProps, OwnProps, State> = (
	state: State
): ProductHighlightStateProps => {
	const { app, routing, resource } = state;
	const sites = app.settings?.sites || [];
	const siteTheme = sites.length > 0 ? getThemeForResource(routing, sites, resource) : undefined;
	const locale = localeSelector(state);
	return {
		siteTheme,
		language: locale,
	};
};

export default connect(mapStateToProps)(injectRoutable(ProductHighlight));
