import * as React from 'react';
import styled from 'styled-components';

const StyledHighlightedLink = styled('a')<HighlightedLinkProps>`
	border-radius: 17px;
	font-weight: 800;
	font-size: 13px;
	line-height: 14px;
	text-align: center;
	padding: 5px 12px;
	text-decoration: none;
	white-space: ${props => (props.wrapText ? 'normal' : 'nowrap')};
	display: inline-block;
	font-family: ${props => props.theme.fonts.primary};
	cursor: pointer;
	background-color: ${props =>
		props.intense ? props.theme.colors.selectedItemBackground : props.theme.colors.brandPrimaryFeatureBackground};
	color: ${props => (props.intense ? props.theme.colors.brandPrimary : props.theme.colors.linkText)};
	border: 1px solid
		${props =>
			props.intense ? props.theme.colors.selectedItemBackground : props.theme.colors.brandPrimaryFeatureBackground};

	&:hover {
		border-color: ${props => props.theme.colors.linkText};
		background-color: ${props => props.theme.colors.white};
		text-decoration: none;
	}
`;

export interface HighlightedLinkProps {
	intense?: boolean;
	wrapText?: boolean;
}

const HighlightedLink: React.FC<HighlightedLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = props => {
	return <StyledHighlightedLink {...props}>{props.children}</StyledHighlightedLink>;
};

export default HighlightedLink;
