import * as React from 'react';
import { media } from '../../../../helpers';
import { LazyImage } from '../../../LazyImage';
import { StaticHighlightCardProps } from '../interfaces';
import styled from 'styled-components';

const StaticHighlightBoxWrapper = styled.a`
	max-width: 355px;
	max-height: 234px;
	position: relative;
	display: block;
	overflow: hidden;
	flex: 1 0 100%;

	& + & {
		margin: 10px 0 0;
	}

	${media.tablet`
		flex: 1 0 calc(50% - 5px);

		& + & {
			margin: 0 0 0 10px;
		}
	`};

	${media.desktop1440`
		flex: 1 0 calc(50% - 10px);
		max-width: 534px;
		& + & {
			margin: 0 0 0 20px;
		}
	`};

	> img {
		min-width: 100%;
	}
`;

const TextWrapper = styled.div`
	max-width: 80%;
	position: absolute;
	bottom: 20%;
	left: 5%;
`;

const Title = styled.div`
	${props => props.theme.typography.heading};
	font-size: 24px;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.08;
	letter-spacing: normal;
	text-align: left;
	color: ${props => props.theme.colors.white};
	text-shadow: 0 2px 24px rgba(0, 0, 0, 0.5);
`;

const StaticHighlightBoxCard: React.FC<StaticHighlightCardProps> = ({ imgSrc, altText, title, link }) => {
	return (
		<StaticHighlightBoxWrapper href={link}>
			<LazyImage src={imgSrc} alt={altText} />
			<TextWrapper>
				<Title>{title}</Title>
			</TextWrapper>
		</StaticHighlightBoxWrapper>
	);
};

export default StaticHighlightBoxCard;
