import * as React from 'react';
import styled from 'styled-components';

const StaticHighlightCardGridWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
`;

const StaticHighlightCardGrid: React.FC = ({ children }) => {
	return <StaticHighlightCardGridWrapper>{children}</StaticHighlightCardGridWrapper>;
};

export default StaticHighlightCardGrid;
