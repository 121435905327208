import styled from 'styled-components';
import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { themeColors } from 'styleguide/themes/oddlygood';

export enum OddlyWrapperBackground {
	Primary,
	Secondary,
	Tertiary,
	White,
}

export interface OddlyWrapperProps {
	background?: OddlyWrapperBackground;
	width?: string;
}

const OddlyWrapper = styled.div<OddlyWrapperProps>`
	background-color: ${({ background }) => {
		switch (background) {
			default:
			case OddlyWrapperBackground.Primary:
				return `${themeColors.greenPesto}`;
			case OddlyWrapperBackground.Secondary:
				return `${themeColors.limeGreen}`;
			case OddlyWrapperBackground.Tertiary:
				return `${themeColors.neutralGray}`;
			case OddlyWrapperBackground.White:
				return `${themeColors.white}`;
		}
	}};

	${({ width }) => width && `width: ${width};`}

	${media.phone`
    margin: 0 -${({ theme }) => theme.grid.gutterInPx(2)} 0 -${({ theme }) => theme.grid.gutterInPx(2)};
  `};

	@media (min-width: ${mediaBreakpoints.desktop1200}px) {
		margin: 0 calc((100vw - 1200px) / -2 - ${({ theme }) => theme.grid.gutter - 0.5}px);
	}
`;

export default OddlyWrapper;
