import * as React from 'react';
import HighlightCardWrapper, { HighlightCardWrapperProps } from '../../Card/HighlightCardWrapper';
import HighlightCardImage from '../../Card/HighlightCardImage';
import HighlightCardContent from '../../Card/HighlightCardContent';
import { StaticHighlightCardProps } from '../interfaces';
import { media } from '../../../../helpers';
import styled from 'styled-components';

const StaticHighlightRecipeGroupCardContent = styled(HighlightCardContent)`
	justify-content: flex-start;
`;

const Text = styled.p`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 13px;
	line-height: 15px;
	color: ${props => props.theme.colors.bodyText};
	margin: 2px 0 0;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;

	${media.tablet`
		display: block;
		-webkit-line-clamp: none;
		font-size: 15px;
		line-height: 18px;
		margin-top: 5px;
	`};
`;

const StaticHighlightRecipeGroupCard: React.FC<StaticHighlightCardProps &
	HighlightCardWrapperProps &
	React.HTMLAttributes<HTMLAnchorElement>> = ({
	isSmall,
	hasBorder,
	print,
	link,
	altText,
	badgeText,
	title,
	text,
	imgSrc,
	...rest
}) => {
	return (
		<HighlightCardWrapper hasBorder={true} href={link} {...rest}>
			<HighlightCardImage
				isSmall={isSmall}
				hasBorder={hasBorder}
				altText={altText}
				imgSrc={imgSrc}
				badgeText={badgeText}
			/>
			<StaticHighlightRecipeGroupCardContent isSmall={isSmall} title={title}>
				{text && <Text>{text}</Text>}
			</StaticHighlightRecipeGroupCardContent>
		</HighlightCardWrapper>
	);
};

export default StaticHighlightRecipeGroupCard;
