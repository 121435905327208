import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

const WidgetText = styled.p<{}>`
	width: 100%;
	max-width: 720px;
	font-size: 15px;
	font-family: ${props => props.theme.fonts.secondary};
	color: ${props => props.theme.colors.bodyText};
	margin-left: auto;
	margin-right: auto;

	${media.desktop`
  	max-width: 600px;
    font-size: 18px;
    padding: 0;
  `};

	${media.desktop1440`
  	max-width: 720px;
  `};
`;

export default WidgetText;
