import * as React from 'react';
import StaticHighlightBoxCard from './StaticHighlightBoxCard';
import StaticHighlightRecipeGroupCard from './StaticHighlightRecipeGroupCard';
import { StaticHighlightCardProps } from './interfaces';

const StaticHighlightCard: React.FC<StaticHighlightCardProps> = ({ style, ...rest }) => {
	switch (style) {
		case 'recipegroup':
			return <StaticHighlightRecipeGroupCard {...rest} />;
		default:
			return <StaticHighlightBoxCard {...rest} />;
	}
};

StaticHighlightCard.defaultProps = {
	style: 'box',
};

export default StaticHighlightCard;
