import * as React from 'react';
import { Section, ContentWrapper } from '../General';
import MediumHeader from 'styleguide/components/Typography/MediumHeader/MediumHeader';
import StaticHighlightCardGrid from 'styleguide/components/Cards/StaticHighlightCard/StaticHighlightCardGrid/StaticHighlightCardGrid';
import StaticHighlightCard from 'styleguide/components/Cards/StaticHighlightCard/StaticHighlightCard';
import { ImageSource } from 'styleguide/interfaces/imgInterfaces';
import styled from 'styled-components';
import { connect, MapStateToProps } from 'react-redux';
import { StaticContentHighlightConfiguration, StaticHighlight } from './interfaces/StaticContentHighlight';
import { staticHighlightActions } from 'common/components/Widgets/reducers/static-highlights';
import { State } from '../../reducers';
import { getSiteUrlId, WithAppSettings } from '../App/services';
import { RoutingState } from '../Routing/reducers';

export interface StaticContentHighlightProps extends WithAppSettings {
	widgetId?: string;
	highlights: StaticHighlight[];
	routing: RoutingState;
}

const StaticHighlightSectionWrapper = styled(Section)`
	display: block;
	padding: 50px 0 60px;

	.medium-header {
		margin-top: 0;
	}
`;

interface StaticContentHighlightOwnProps {
	isMock?: boolean;
	mockHighlights?: StaticHighlight[];
}

type Props = StaticContentHighlightOwnProps &
	StaticContentHighlightConfiguration &
	StaticContentHighlightProps &
	StaticContentHighlightDispatchProps;

class StaticContentHighlight extends React.Component<Props> {
	public componentDidMount() {
		const { routing, settings, isMock } = this.props;
		if (routing.pathname && !isMock) {
			const siteUrlId = settings ? getSiteUrlId(routing, settings.sites) : undefined;
			this.props.fetchStaticHighlights({ siteUrlId: siteUrlId || '', resourceUrl: routing.pathname });
		}
	}

	public render() {
		const { widgetId, highlights, title, disableWrapper } = this.props;

		if (highlights.length < 1) {
			return null;
		}

		if (disableWrapper) {
			return this.renderCards();
		}

		return (
			<StaticHighlightSectionWrapper id={widgetId} isHighlighted={true} isFullWidth={true}>
				<ContentWrapper>
					{title && (
						<MediumHeader className="medium-header" greyed={true} tagName="h3">
							{title}
						</MediumHeader>
					)}
					<StaticHighlightCardGrid>{this.renderCards()}</StaticHighlightCardGrid>
				</ContentWrapper>
			</StaticHighlightSectionWrapper>
		);
	}

	public renderCards() {
		const { highlights, staticBadgeText, cardStyle } = this.props;

		const visibleHighlights = highlights.slice(0, 2);

		return visibleHighlights.map(({ imageUrl, title, text, linkText, linkUrl }, i) => (
			<StaticHighlightCard
				key={i}
				imgSrc={this.getImagePresetSizes(imageUrl)}
				title={title}
				altText={linkText}
				link={linkUrl}
				text={text}
				badgeText={staticBadgeText}
				style={cardStyle}
			/>
		));
	}

	private getImagePresetSizes(image: string): string | ImageSource[] {
		const { cardStyle } = this.props;

		switch (cardStyle) {
			case 'recipegroup':
				return `${image}/358x231-recipe-highlight`;

			default:
				return [
					{
						url: `${image}/534x234-static-highlight`,
						media: 'desktop1440',
						width: 1440,
					},
					{
						url: `${image}/355x234-static-highlight`,
						media: 'desktop',
						width: 1000,
					},
				];
		}
	}
}

export interface StaticContentHighlightDispatchProps {
	fetchStaticHighlights: typeof staticHighlightActions.fetchStaticHighlights;
}

const mapStateToProps: MapStateToProps<StaticContentHighlightProps, StaticContentHighlightOwnProps, State> = (
	{ resource, routing, app },
	{ isMock, mockHighlights }
): StaticContentHighlightProps => {
	return {
		highlights: isMock ? mockHighlights || [] : resource.staticHighlights,
		routing,
		settings: app.settings,
	};
};

export default connect(mapStateToProps, {
	fetchStaticHighlights: staticHighlightActions.fetchStaticHighlights,
})(StaticContentHighlight);
