import * as React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../../helpers';

const RecipeCardGridWrapper = styled('div')<RecipeCardGridProps>`
	max-width: 1200px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: stretch;
	justify-content: center;
	margin: -10px -5px;
	width: 100%;
	height: 100%;

	${props => {
		if (props.layout === 'threeColumn') {
			return css`
				${media.desktop`
					margin: -15px;
				`};

				${media.desktop1440`
					margin: -20px;
				`};
			`;
		}
		return css`
			margin: -10px -5px;
		`;
	}};

	.recipe-card {
		/* IE */
		flex: 0 1 174px;
		flex: 0 1 calc(50% - 10px);
		width: 174px;
		width: calc(50% - 10px);
		margin: 10px 5px;

		${media.tablet<RecipeCardGridProps>`
			${props => {
				if (props.layout && ['threeColumn', 'fourColumn'].indexOf(props.layout) > -1) {
					return css`
						flex: 0 1 calc(100% / 3 - 10px);
						width: calc(100% / 3 - 10px);
					`;
				}
				return css`
					width: calc(100% / 2 - 10px);
				`;
			}};

		`};

		${media.desktop<RecipeCardGridProps>`
			/* IE */
			flex: 0 1 174px;

			${props => {
				if (props.layout === 'threeColumn') {
					return css`
						margin: 15px;
						flex: 0 1 calc(${100 / 3}% - 30px);
						width: calc(${100 / 3}% - 30px);
					`;
				}
				if (props.layout === 'fourColumn') {
					return css`
						flex: 0 1 calc(${100 / 4}% - 10px);
						width: calc(${100 / 4}% - 10px);
					`;
				}
				return css`
					flex: 0 1 calc(${100 / 5}% - 10px);
					width: calc(${100 / 5}% - 10px);
				`;
			}};
		`};

		${media.desktop1440<RecipeCardGridProps>`
			/* IE */
			flex: 0 1 174px;

			${props => {
				if (props.layout === 'threeColumn') {
					return css`
						margin: 20px;
						flex: 0 1 calc(${100 / 3}% - 40px);
						width: calc(${100 / 3}% - 40px);
					`;
				}
				if (props.layout === 'fourColumn') {
					return css`
						flex: 0 1 calc(${100 / 4}% - 10px);
						width: calc(${100 / 1}% - 10px);
					`;
				}
				return css`
					flex: 0 1 calc(${100 / 6}% - 10px);
					width: calc(${100 / 6}% - 10px);
				`;
			}};
		`};
	}
`;

interface RecipeCardGridProps {
	layout?: 'list' | 'fourColumn' | 'threeColumn';
}

const RecipeCardGrid: React.FC<RecipeCardGridProps & React.HTMLAttributes<HTMLDivElement>> = ({ layout, children }) => {
	return <RecipeCardGridWrapper layout={layout}>{children}</RecipeCardGridWrapper>;
};

RecipeCardGrid.defaultProps = {
	layout: 'list',
};

export default RecipeCardGrid;
