import styled from 'styled-components';
import { mediaBreakpoints } from 'styleguide/helpers/media';
// import { media, mediaBreakpoints } from 'styleguide/helpers/media';
import { themeColors } from 'styleguide/themes/oddlygood';
import { OddlyWrapperBackground } from './OddlyWrapper';

export interface OddlyWrapperInnerProps {
	background?: OddlyWrapperBackground;
	desktopPadding?: number;
	tabletPadding?: number;
}

const OddlyWrapperInner = styled.div<OddlyWrapperInnerProps>`
	background-color: ${({ background }) => {
		switch (background) {
			default:
			case OddlyWrapperBackground.Primary:
				return `${themeColors.greenPesto}`;
			case OddlyWrapperBackground.Secondary:
				return `${themeColors.limeGreen}`;
			case OddlyWrapperBackground.Tertiary:
				return `${themeColors.neutralGray}`;
			case OddlyWrapperBackground.White:
				return `${themeColors.white}`;
		}
	}};
	color: ${({ background }) => {
		switch (background) {
			default:
			case OddlyWrapperBackground.Primary:
				return `${themeColors.white}`;
			case OddlyWrapperBackground.Secondary:
			case OddlyWrapperBackground.Tertiary:
				return `${themeColors.greenPesto}`;
			case OddlyWrapperBackground.White:
				return `${themeColors.greenPesto}`;
		}
	}};

	padding-bottom: 10px;

	@media (min-width: ${mediaBreakpoints.tablet}px) {
		padding-bottom: ${({ tabletPadding }) => (tabletPadding ? `${tabletPadding}px` : `0px`)};
	}

	@media (min-width: ${mediaBreakpoints.desktop}px) {
		padding-bottom: ${({ desktopPadding }) => (desktopPadding ? `${desktopPadding}px` : `120px`)};
	}

	@media (min-width: ${mediaBreakpoints.desktop1100}px) {
		padding-bottom: ${({ desktopPadding }) => (desktopPadding ? `${desktopPadding}px` : `200px`)};
	}
`;

export default OddlyWrapperInner;
