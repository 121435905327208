import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';
import { themeColors } from 'styleguide/themes/oddlygood';

export enum OddlySubTitleType {
	Primary,
	Extra1,
	Extra2,
	Simple,
}

interface Title3Props {
	simple?: boolean;
}

const Title3 = styled.h1<Title3Props>`
	${({ theme }) => theme.typography.heading};
	font-size: 34px;
	line-height: 1;
	text-transform: uppercase;

	${({ simple }) =>
		!simple &&
		`
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 0;
		& span {
			margin: 5px;
		}
		`}
	${({ simple }) =>
		simple &&
		`
		margin-bottom: 8px;
		padding: 0;
	`}
`;

interface TitleInnerProps {
	background: string;
	color: string;
	simple?: boolean;
}

const TitleInner = styled.span<TitleInnerProps>`
	${media.phone560`
    font-size: 40px;
  `};
	font-size: 30px;
	margin: 5px 10px 5px 0px;
	${({ simple }) =>
		!simple &&
		`
		padding: 10px 25px 10px 25px;
	`}
	background-color: ${({ background }) => background};
	color: ${({ color }) => color};
`;

interface OddlyTitleProps {
	title: string;
	type?: OddlySubTitleType;
}

const OddlySubTitle: React.FunctionComponent<OddlyTitleProps> = ({ title, type }) => {
	const realType = type || OddlySubTitleType.Primary;
	let backgroundColor = themeColors.greenPesto;
	let foregroundColor = themeColors.white;
	let simple = false;
	switch (realType) {
		case OddlySubTitleType.Extra1:
			backgroundColor = themeColors.blockOrange;
			break;
		case OddlySubTitleType.Extra2:
			backgroundColor = themeColors.blockPurple;
			break;
		case OddlySubTitleType.Simple:
			backgroundColor = themeColors.transparent;
			foregroundColor = themeColors.greenPesto;
			simple = true;
			break;
	}

	return (
		<Title3 simple={simple}>
			<TitleInner simple={simple} color={foregroundColor} background={backgroundColor}>
				{title}
			</TitleInner>
		</Title3>
	);
};

export default OddlySubTitle;
